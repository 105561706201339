<template>
    <div class="asyou-join-page page-p-t">
        <div class="margin-both">
            <p class="margin-cus">在招职位</p>
            <p class="filter-desc">职位筛选</p>
            <el-input style="margin-top: 0.24rem;" placeholder="请输入职位关键词" v-model="query.recruitName" @keyup.enter.native="getList" @change="getList"></el-input>
            <el-row v-for="l in list" :key="l.recruitId">
                <el-col class="item-list" :span="16">
                    <p class="title">{{ l.recruitName }}</p>
                    <p class="detail"><span>{{ l.recruitLocation }}</span> | <span>{{ l.createTime }}</span></p>
                </el-col>
                <el-col class="item-list" :offset="4" :span="4" style="text-align: right;">
                    <span @click="jumpDetail(l)">
                        <img src="@/assets/img/aboutAsyou/look-detail.png"/>
                    </span>
                </el-col>
            </el-row>

            <div class="page-box">
                <el-pagination
                @current-change="handleCurrentChange"
                    :current-page.sync="query.pageNum"
                    :page-size="query.pageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    @size-change="handleSizeChange"
                    layout=" prev, pager, next, jumper, total"
                    :total="response.total"
                >

                </el-pagination>
            </div>

        </div>

        <ContactInformation></ContactInformation>
    </div>
</template>
<script>
import { recruitList } from '@/api/index';
import ContactInformation from '@/components/footer.vue';

export default {
    name: 'Join',
    data() {
        return {
            param: '',
            query: {
                pageNum: 1,
                pageSize: 10,
                recruitName: ''
            },
            list: [],
            response: {}
        }
    },
    components: {
        ContactInformation
    },
    methods: {
        handleCurrentChange(data) {
            this.$set(this.query, "pageNum", data);
            this.queryList();
        },
        handleSizeChange(val) {
            this.$set(this.query, "pageSize", val);
            this.$set(this.query, "pageNum", 1);
            this.queryList();
        },
        getList() {
            recruitList(this.query).then(res => {
                this.list = res.data.rows;
                this.response = res.data;
            })
        },
        jumpDetail(l) {
            this.$router.push({
                path: 'jobDetail',
                query: {
                    recruitId: l.recruitId
                }
            })
        }
    },
    mounted() {
        this.getList();
    }
}
</script>
<style lang="scss">
.asyou-join-page {
  .el-input__inner {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 1px solid rgba($color: #282C4D, $alpha: 1) !important;
    border-radius: 5px !important;
    color: white !important;
  }
  .el-input__inner:focus {
    border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80) !important;
  }
  .page-box {
    .el-pagination .btn-next, .el-pagination .btn-prev {
      background: center center no-repeat rgba(0, 0, 0, 0) !important;
    }
    .el-pagination {
      color: white !important;
    }
    .el-pager li {
      background: rgba($color:rgba(0, 0, 0, 0), $alpha: 1) !important;
    }
    .el-pagination__total {
      margin-left: 10px;
    }
  }
}


</style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";

    .margin-both {
        margin-left: $whole-lr;
        margin-right: $whole-lr;
    }
    .asyou-join-page {
        overflow-y: scroll;

        .margin-cus {
            margin-top: 1.24rem;
            @include font-2;
        }

        .filter-desc {
            margin-top: 0.53rem;
            @include de-0-18;
        }

        .item-list {
            margin-top: 0.8rem;

            .title {
                @include font-section;
                letter-spacing: 4px;
            }

            .detail {
                margin-top: 0.12rem;
                @include de-0-16;
                letter-spacing: 2px;
            }

            img {
                cursor: pointer;
                width: 1.94rem;
                height: 0.59rem;
            }
        }
        .page-box {
            margin-top: 1.5rem;
            padding-bottom: 1.5rem;
            text-align: center;
        }
    }
</style>
